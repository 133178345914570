<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>
 <div class="link-div">
    <span style="font-size: 13px">您当前的位置：</span>
    <el-link @click="toIndex">首页</el-link>
    <span style="font-size: 13px">&nbsp;>&nbsp;</span>
    <el-link @click="toCurrent">技术研发</el-link>
    <p class="link-p"></p>
  </div>

  <div style="margin-top: 50px; padding-right: 270px;padding-left:270px;">
    <el-row :gutter="20" style="margin-bottom: 50px;text-align: center;">
      <div style="padding-left:510px;">
        <img src="../assets/image/technology/technology-1.png" class="image" />
      </div>
      <div>
        <img src="../assets/image/technology/technology-2.png" class="image" />
      </div>
      <div style="padding-left:100px;">
        <img src="../assets/image/technology/technology-3.png" class="image" />
      </div>
      <div style="padding-left:200px;">
        <img src="../assets/image/technology/technology-4.png" class="image" />
      </div>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {
    };
  },
  methods:{
      toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/technology",
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.describe {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
}
.describe-p {
  line-height: 30px;
}
.describes {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
  background: #098af3;
}
.el-link {
  color: #000;
  margin-top: -3px;
}
.link-p{
  border-bottom: 1px solid #bbbbbdec;
}
.link-div {
  padding-left: 270px;
  text-align: left;
  margin-top: 30px;
  padding-right: 270px;
}
</style>